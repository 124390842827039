enum authorities {
/*
  add your authorities here. E.g:
*/
  USER_CREATE = "USER_CREATE",
  USER_READ = "USER_READ",
  USER_DEACTIVATE = "USER_DEACTIVATE",
}

export default authorities;
